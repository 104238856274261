import { IOnCardTagType } from "./types";

export const onCardTagBgMapping: Record<IOnCardTagType, string> = {
  positive: "linear-gradient(88.44deg, #12714F 0.02%, #1CA099 100%)",
  warning: "linear-gradient(88.04deg, #C06D0C 0%, #F2C21A 100%)",
  negative: "linear-gradient(87.77deg, #F5515F -0.75%, #9F0404 100%)",
  generic: "linear-gradient(90deg, #03BAE1 0%, #3A7BD5 100%)",
  offer: "linear-gradient(87.29deg, #C86DD7 0%, #3023AE 100%);",
  neutral: "black",
};
