import styled from "styled-components";
import { onCardTagBgMapping } from "./external";
import { OnCardTagProps } from "./types";

export const OnCardTag = styled.div<OnCardTagProps>`
  padding: 4px 8px;
  border-radius: 19px;
  font-weight: 700;
  font-size: 12px;
  line-height: 11px;
  color: #fff;
  font-family: "Open Sans";
  text-transform: uppercase;
  text-align: center;
  background: ${(props) =>
    onCardTagBgMapping[(props as OnCardTagProps).type || "positive"]};
`;
