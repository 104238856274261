import styled from "styled-components";
import { OnCardTag } from "../OnCardTag";

export const LandingTestimonialsStyled = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  // margin-bottom: 137px;
`;

export const LandingTestTitle = styled.h3`
  font-family: "Raleway";
  font-weight: 500;
  font-size: 56px;
  line-height: 72px;
  color: #000000;
  margin-bottom: 34px;
  display: flex;
  align-items: center;
`;

export const LandingTest = styled.div`
  display: inline-block;
`;

export const LandingTestWrap = styled.div`
  display: flex;
  width: 575px;
  padding: 32px 30px;
  background: #fff;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  margin-right: 20px;

  & .info {
    white-space: initial;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #4a4a4a;
  }
`;

export const LandingTestImg = styled.div`
  width: 150px;
  height: 250px;
  flex-shrink: 0;
  position: relative;
  margin-right: 20px;
  z-index: 1;
`;

export const LandingTextCrd = styled(OnCardTag)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const PersonName = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #012136;
  margin-bottom: 2px;
  display: block;
`;

export const LandingTestCntnt = styled.div`
  z-index: 1;
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

export const SlideShowContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
  max-width: 1200px;
`;

export const SlideShowSLider = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
`;

export const SlideDots = styled.div`
  text-align: center;

  .slideshowDot {
    background: #3f3d56;
    opacity: 0.2;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 16px;
    cursor: pointer;
  }

  .slideshowDot.active {
    width: 32px;
    height: 12px;
    background: #000000 !important;
    opacity: 1;
    border-radius: 6px;
  }
`;

export const StarRating = styled.div`
  display: flex;
  gap: 8px;

  &.starRateFull {
    position: absolute;
    top: 0;
  }
`;

export const StarRatingWrap = styled.div`
  margin-bottom: 12px;
  position: relative;
`;
