import { LandingWhiteSec } from "../../shared-styled-components";
import LandingTestimonials from "../LandingTestimonials/LandingTestimonials";

const TestimonialSection = ({ title, data, starEmpty, starFull }: any) => {
  return (
    <LandingWhiteSec>
      <LandingTestimonials
        title={title}
        data={data}
        starEmpty={starEmpty}
        starFull={starFull}
      />
    </LandingWhiteSec>
  );
};

export default TestimonialSection;
