import React from "react";
import { IOnCardTagType } from "../OnCardTag/types";
import { Image } from "../Image";
import {
  LandingTest,
  LandingTestCntnt,
  LandingTestImg,
  LandingTestimonialsStyled,
  LandingTestTitle,
  LandingTestWrap,
  LandingTextCrd,
  PersonName,
  SlideDots,
  SlideShowContainer,
  SlideShowSLider,
  StarRating,
  StarRatingWrap,
} from "./LandingTestimonials.style";

const LandingTestimonials = ({ title, data, starEmpty, starFull }: any) => {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1
        ),
      250000
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  const testimonialCategoryTagThemeMapping: Record<string, IOnCardTagType> = {
    lending: "positive",
    forex: "generic",
    insurance: "offer",
  };

  return (
    <LandingTestimonialsStyled>
      <LandingTestTitle>{title}</LandingTestTitle>
      <SlideShowContainer>
        <SlideShowSLider
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 595}px, 0, 0)` }}
        >
          {data &&
            data.map((item: any, index: number) => {
              return (
                <LandingTest className="slide" key={index}>
                  <LandingTestWrap>
                    <LandingTestImg>
                      <Image src={item.personImg} width="150" height="250" />
                      <LandingTextCrd
                        type={
                          testimonialCategoryTagThemeMapping[
                            item.personCrdClass
                          ]
                        }
                      >
                        {item.personCard}
                      </LandingTextCrd>
                    </LandingTestImg>
                    <LandingTestCntnt>
                      <React.Fragment>
                        <PersonName>{item.personName}</PersonName>
                        <StarRatingWrap>
                          <StarRating>
                            {Array.from({ length: 5 }, (_, index) => (
                              <Image
                                key={index}
                                {...starEmpty}
                                className="starRate"
                              />
                            ))}
                          </StarRating>
                          <StarRating className="starRateFull">
                            {Array.from(
                              { length: item.personRating },
                              (_, index) => (
                                <Image key={index} {...starFull} />
                              )
                            )}
                          </StarRating>
                        </StarRatingWrap>
                      </React.Fragment>
                      <p className="info">{item.testInfo}</p>
                    </LandingTestCntnt>
                  </LandingTestWrap>
                </LandingTest>
              );
            })}
        </SlideShowSLider>

        <SlideDots className="slideshowDots">
          {data.map((_: any, idx: number) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </SlideDots>
      </SlideShowContainer>
    </LandingTestimonialsStyled>
  );
};

export default LandingTestimonials;
